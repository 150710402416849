import '@fontsource/lato/100.css';
import './src/styles/global.css';

import React from 'react';

import { BannerProvider } from '@context/BannerContext';

export const wrapRootElement = ({ element }) => (
  <BannerProvider>{element}</BannerProvider>
);
