import React from 'react';

const defaultContext = {
  banner: true,
  freeOpenDay: false,
  toggleBanner: () => {},
  hideBanner: () => {},
};

export const BannerContext = React.createContext(defaultContext);

class BannerProvider extends React.Component {
  state = {
    banner: defaultContext.banner,
    freeOpenDay: defaultContext.freeOpenDay,
  };

  toggleBanner = () => this.setState({ banner: !this.banner });
  hideBanner = () => this.setState({ banner: false });

  render() {
    const { children } = this.props;
    const { banner, freeOpenDay } = this.state;

    return (
      <BannerContext.Provider
        value={{
          banner,
          freeOpenDay,
          toggleBanner: this.toggleBanner,
          hideBanner: this.hideBanner,
        }}
      >
        {children}
      </BannerContext.Provider>
    );
  }
}

export default BannerContext;

export { BannerProvider };
